import { Snackbar } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URL, URL } from "../../../variables";
import Video from "../../ReusableComponents/Video";
import { get, post } from "../../../services/api";
import { logout } from "../../../actions/AppActions";

const UsersLessonPage = ({trainingId,lastWatch, contentId, moduloId,videoPath,url_cover, onCompleteSync, previousVideoLink, nextVideoLink}) => {
    console.log(videoPath)
    const [sendingData, SetSendingData] = useState(false);
    const [startVideoAt, SetStartVideoAt] = useState(lastWatch);
    const [url, setUrl] = useState('');
    const dispatch = useDispatch();


    const [lastWatchTime, SetLastWatchTime] = useState(lastWatch);
    console.log(lastWatchTime)
    // const [watchTime, SetWatchTime] = useState(0);
    const watchTime = useRef();
    const [snackbar, SetSnackbar] = useState(false);
    const [snackbarMessage, SetSnackbarMessage] = useState("");

    const userId = useSelector(store => store.AppReducer.user.id);

    const last_time = useRef();

    const UpdateWatchTime = async (time) => {
        if(sendingData==true || last_time.current==time){
            return;
        }
        last_time.current=time;
        let token = localStorage.getItem("token");
        if (!token || !moduloId || sendingData) return;
        SetSendingData(true);
        let form = {}
        form.treinamento_id = trainingId;
        form.modulo_id = moduloId;
        form.conteudo_id = contentId;
        form.watch_time = Math.floor(time);
        form.total_watch_time = parseFloat(watchTime.current ?? 0);
        form.completed =  false;

        let response = await post("api/fiscal/watchtime", form, token);
        if(response?.status=='logout'){
            dispatch(logout());
            return;
        }
        else if(response.status==false){
            
        }
        SetSendingData(false);
    }

    const SendCompleteSignal = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        let form = {}
        form.treinamento_id = trainingId;
        form.modulo_id = moduloId;
        form.conteudo_id = contentId;
        form.watch_time = parseFloat(watchTime.current ?? 0);
        form.total_watch_time = parseFloat(watchTime.current ?? 0);
        form.completed =  true;

        let response = await post("api/fiscal/watchtime", form, token);
        if(response?.status=='logout'){
            dispatch(logout());
            return;
        }
        else if(response.status==false){
            
        }
        else{
            onCompleteSync();
        }
    }

  

    

    

    

    return (
        <div>
            {videoPath && <Video
                // src={(contentId != "" && contentId !== undefined && contentId) ? API_URL + "lessons/video/" + contentId : ""}
                src={videoPath}
                url_cover={url_cover}

                speeds={[0.25, 0.5, 0.75, 1, 1.25, 1.5, 2]}
                VolumeTrailColor="white"
                start_at={startVideoAt}
                lastTotalWatchTime={lastWatchTime}
                CurrentTime={(time) => {
                    time = ~~time;
                    if (time != 0 && time % 10 == 0) UpdateWatchTime(time);
                }}
                OnWatched={() => {
                    SendCompleteSignal()
                }}
                OnVideoEnded={(x) => {
                    let videoLength = x.videoLength;
                    console.log(watchTime.current + " " + videoLength)
                    if (!(watchTime.current >= videoLength - 20)) {
                        SetSnackbar(true);
                        SetSnackbarMessage("O vídeo terminou, porém você não assistiu o tempo necessário para marcar como visto");
                    }
                }}
                WatchTime={value => {
                    console.log('watch time ',watchTime.current)
                    watchTime.current=value;
                    // SetWatchTime(value);
                }}
                previousVideoLink={previousVideoLink}
                nextVideoLink={nextVideoLink}
                hidden={!contentId}
            />}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={10000}
                onClose={() => SetSnackbar(false)}
                open={snackbar}
                message={snackbarMessage}
            />
        </div>
    );
}

export default UsersLessonPage;