// Libraries imports
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Components imports
import DefaultButton from "../../ReusableComponents/DefaultButton";
import TabPanel from "../../ReusableComponents/TabPanel";
// Utils imports
import { Tab, Tabs } from "@material-ui/core";

import { get, post } from "../../../services/api";
import { logout } from "../../../actions/AppActions";

// CSS imports
import "react-loading-skeleton/dist/skeleton.css";

import VideocamIcon from '@material-ui/icons/Videocam';
import DescriptionIcon from '@material-ui/icons/Description';
import LaunchIcon from '@material-ui/icons/Launch';
import { Accordion, AccordionSummary, AccordionDetails, Menu, MenuItem, Button, ButtonBase, Checkbox, CircularProgress } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RiArrowGoBackLine } from "react-icons/ri";
import { MdDvr } from "react-icons/md";
import UsersLessonPage from "./UsersLessonPage";
import { Link } from "react-router-dom";
import moment from "moment";

class ShowTreinamentos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      descricao: "",
      modulos: [],
      loading: true,
      redirect: false,
      selectedContent: {},
      tabValue: 0,
      conteudos: {},
      conteudos_order: {},
      treinamento: {},
      questions_blockeds: [],
      respostas: {},
      loading_resposta: {}

    };
    this.videoRef = React.createRef();
    this.modalRef = React.createRef();
    this.questoesRef = {}
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.getTreinamentos(id);


  }



  componentWillUnmount() {
    clearTimeout(this.timeout_salvar_questao)
  }



  async getTreinamentos(id) {
    this.setState({ loading: true });
    try {
      const data = await get(
        `api/fiscal/get_training/${id}`,
        this.props.token
      );
      console.log(data)
      const { nome, descricao, modulos, status } = data.data;
      if (data?.status == 'logout') {
        this.props.logout()
        return;
      }
      if (status != 'Em andamento') {
        this.props.history.push('/treinamentos');
        return;
      }
      let conteudos = {}
      let conteudos_order = {}

      let order = 0;
      modulos.map((item) => {
        item.conteudos.map((item2) => {
          conteudos[item2.modulo_id + '_' + item2.id] = item2;
          conteudos[item2.modulo_id + '_' + item2.id].order = order;
          conteudos_order[order] = item2.modulo_id + '_' + item2.id
          order++
        })
      })

      console.log(modulos)
      this.setState({ treinamento: data.data, conteudos_order, conteudos, nome, descricao, modulos, selectedContent: (data.lastWatch ? data.lastWatch : Object.values(conteudos)[0]) });



      // if (ultimoModuloId && ultimoConteudoId) {
      //   await this.carregarUltimoModuloEConteudo(
      //     ultimoModuloId,
      //     ultimoConteudoId
      //   );
      // } else if (modulos.length > 0) {
      //   await this.carregarPrimeiroModuloEConteudo(modulos[0].id, id);
      // }
    } catch (error) {
      this.setState({ errorMsg: error.message || "Erro ao buscar o módulo" });
    } finally {
      this.setState({ loading: false });
    }
  }




  async SendCompleteSignal(selectedContent) {
    let token = localStorage.getItem("token");
    if (!token) return;

    let form = {}
    form.treinamento_id = this.props.match.params.id;
    form.modulo_id = selectedContent.modulo_id;
    form.conteudo_id = selectedContent.id;
    form.watch_time = parseFloat(0);
    form.total_watch_time = parseFloat(0);
    form.completed = true;

    let response = await post("api/fiscal/watchtime", form, token);
    if (response?.status == 'logout') {
      this.props.logout();
      return;
    }
    else if (response.status == false) {

    }
  }

  async salvar_resposta(questao_id, alternativa_id, historico) {

    const response = await post(
      `api/fiscal/salvar_resposta`,
      {
        questao_id,
        alternativa_id,
        treinamento_id: this.state.treinamento.id,
        modulo_id: this.state.selectedContent.modulo_id,
        conteudo_id: this.state.selectedContent.id,
      },
      this.props.token
    );
    if (response?.status == 'logout') {
      this.props.logout();
      return;
    }
    else if (response.status == 'server_error') {
      this.timeout_salvar_questao = setTimeout(()=>{
        this.salvar_resposta(questao_id, alternativa_id, historico)
      },5000)
    }
    else if(response.status=='invalid'){
      //treinamento acabou
    }
    else{
      
    }
  }






  render() {
    const {
      nome,
      descricao,
      modulos,
      loading,
      redirect,
      selectedContent


    } = this.state;


    if (redirect) {
      return <Redirect to="/treinamentos" />;
    }
    console.log(selectedContent)
    return (
      <div className="card" id={loading.toString()}>
        <div className="card-body">

          {!loading && <>
            <div className="float-right">
              <Link onClick={() => {

              }} to='/treinamentos' className="btn btn-secondary btn-outline btn-circle m-r-5" ><i
                className="mdi mdi-reply"
                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                  Voltar</i></Link>
            </div>
            <div className="w100">
              <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black', marginBottom: 10 }}>{nome}</h4>
              <p style={{ marginBottom: 5 }}><b>Início: </b>{moment(this.state.treinamento.instituicoes[0].data_inicial).format('DD/MM/YYYY HH:mm')}</p>
              <p style={{ marginBottom: 5 }}><b>Encerramento: </b>{moment(this.state.treinamento.instituicoes[0].data_final).format('DD/MM/YYYY HH:mm')}</p>
              <p style={{ marginBottom: 5 }}><b>Status: </b> <span style={{ color: this.state.treinamento.status == 'Não iniciado' ? 'darkgoldenrod' : (this.state.treinamento.status == 'Encerrado' ? 'red' : 'green') }}>{this.state.treinamento.status}</span></p>
              <p style={{ marginBottom: 5 }}><b>Sobre o curso: </b> <p style={{ color: 'black', margin: 0, whiteSpace: 'pre-line' }}>{descricao}</p></p>

              {/* <h2 className="title">{nome}</h2> */}

            </div>


            <br />
            <div className="course-page flex fdrow margin-bottom">
              <div
                id="left-side"
                className={"flex3"}
                style={{
                  width: "-webkit-fill-available"
                }}
              >
                {this.state.selectedContent?.type == 'video' ? <UsersLessonPage
                  contentId={this.state.selectedContent.id}
                  moduloId={this.state.selectedContent.modulo_id}
                  url_cover={this.state.selectedContent.thumbnail}
                  videoPath={this.state.selectedContent.file}
                  trainingId={this.props.match.params.id}
                  lastWatch={parseInt(this.state.selectedContent.watchtime?.watch_time ?? 0)}
                  onCompleteSync={() => {
                    // AuthCourse();
                    // GetLessons();
                  }}
                  previousVideoLink={(() => {
                    // let currentIndex = videosLinks.findIndex(x => x["id"] == query.get("video"));
                    // if (currentIndex === 0) return null;
                    // else {
                    //     if (videosLinks[currentIndex - 1] === undefined) return null;
                    //     let previousLessonId = videosLinks[currentIndex - 1]["lesson_id"];
                    //     let previousVideoId = videosLinks[currentIndex - 1]["id"];
                    //     return `/courses/course/${id}?video=${previousVideoId}&lesson=${previousLessonId}`;
                    // }
                  })()}
                  nextVideoLink={(() => {
                    // let currentIndex = videosLinks.findIndex(x => x["id"] == query.get("video"));
                    // if (currentIndex === videosLinks.length) return null;
                    // else {
                    //     if (videosLinks[currentIndex + 1] === undefined) return null;
                    //     let nextLessonId = videosLinks[currentIndex + 1]["lesson_id"];
                    //     let nextVideoId = videosLinks[currentIndex + 1]["id"];
                    //     return `/courses/course/${id}?video=${nextVideoId}&lesson=${nextLessonId}`;
                    // }
                  })()}
                /> :
                  <div>
                    <iframe
                      src={this.state.selectedContent?.file}
                      hidden={!this.state.selectedContent}
                      style={{
                        width: "100%",
                        height: "30vw",
                        minHeight: "22em"
                      }}
                    />
                  </div>}
                <Tabs
                  value={this.state.tabValue}
                  onChange={(_, value) => this.setState({ tabValue: value })}
                  style={{ backgroundColor: "#ececf1", minHeight: "51px" }}
                  indicatorColor="primary"
                  textColor="primary"
                  variant={"scrollable"}
                  scrollButtons={"on"}
                >
                  {/* <Tab label="Sobre o curso" /> */}
                  <Tab label="Sobre o módulo" />
                  {this.state.selectedContent?.questoes?.length > 0 && <Tab label="Questões" />}

                  <Tab label="Conteúdo do curso" className="d-lg-none d-block" />
                </Tabs>
                {/* <TabPanel value={this.state.tabValue} index={0}>
                  {descricao}
                </TabPanel> */}
                <TabPanel value={this.state.tabValue} index={0}>
                  {(modulos.length != 0 && modulos) && modulos.map((x, index) => {
                    if (x["id"] == this.state.selectedContent?.modulo_id) return <p key={index}>{x["description"]}</p>
                  })}
                </TabPanel>
                {this.state.selectedContent?.questoes?.length > 0 && <TabPanel value={this.state.tabValue} index={1}>
                  {<>
                    <div className="card">
                      <div className="card-body" style={{
                        background: '#fff',
                        borderRadius: '10px'
                      }}>
                        {this.state.selectedContent?.questoes.map((item, id) => (
                          <>
                            {id != 0 && <hr />}

                            {<h6 className="mt-0 header-title d-sm-block" style={{ textAlign: 'right' }}>
                              {item.nome}</h6>}

                            <div className="">
                              <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                <b>{id + 1})</b></h5>
                            </div>


                            {<div ref={(ref) => this.questoesRef[item.id] = ref} className="row" >
                              <div className="col-12 col-sm-11">

                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: item.enunciado }}></div>

                                {/* {this.state.questions_blockeds.includes(item.id) && <p style={{ color: 'red' }}>Você não pode mais alterar a resposta desta questão pois realizou uma pausa após visualizá-la</p>} */}
                                <form>
                                  {item.alternativas.map((alternativa, id2) => (



                                    <div key={id2} className="row">
                                      <div className="col-2 pt-3 pb-3 d-sm-flex" >

                                        <input onChange={() => {
                                          if (this.state.questions_blockeds.includes(item.id)) {
                                            return
                                          }
                                          let respostas = { ...this.state.respostas };
                                          let historico = respostas[item.id];
                                          respostas[item.id] = alternativa.id;
                                          this.setState({ respostas }, () => {
                                            let loading_resposta = { ...this.state.loading_resposta };
                                            loading_resposta[item.id] = true;
                                            this.setState({ loading_resposta });
                                            this.salvar_resposta(item.id, alternativa.id, historico);
                                          });
                                        }} className="form-check-input" type="radio" name="resposta_id"
                                          disabled={this.state.loading_resposta[item.id] == true || this.state.questions_blockeds.includes(item.id)}
                                          // value="{{$alternativa->id}}"
                                          style={{
                                            position: 'relative', marginLeft: 0, marginTop: -16,
                                            marginRight: '10px'
                                          }} checked={this.state.respostas[item.id] != undefined && this.state.respostas[item.id] == alternativa.id} />
                                        {(id2 + 1) == 1 && <b>a) </b>}
                                        {(id2 + 1) == 2 && <b>b) </b>}
                                        {(id2 + 1) == 3 && <b>c) </b>}
                                        {(id2 + 1) == 4 && <b>d) </b>}
                                        {(id2 + 1) == 5 && <b>e) </b>}
                                      </div>
                                      <div dangerouslySetInnerHTML={{ __html: alternativa.name }} className="col-10 pt-3 pb-3 d-sm-block jodit-wysiwyg" style={{}}>

                                      </div>


                                    </div>
                                  ))}
                                </form>

                              </div>







                            </div>}

                          </>
                        ))}
                      </div></div>
                  </>}
                </TabPanel>}

                <TabPanel value={this.state.tabValue} index={this.state.selectedContent?.questoes?.length > 0 ? 2 : 1} className="d-lg-none d-block">
                  <div className="w100 flex jcc">
                    {loading && modulos.length == 0 ? <CircularProgress /> : ""}
                  </div>
                  {modulos.length != 0 && modulos.map((value, index) => (
                    <Accordion
                      key={index}
                      style={{
                        background: "#ececf1"
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{
                          fontWeight: "700",
                          textTransform: "capitalize"
                        }}
                      >
                        <div className="text-truncate" style={{ maxWidth: "300px" }}>
                          {index + 1}. {value["name"]}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails
                        className="flex fdcolumn "
                        style={{ padding: 0 }}
                      >
                        {value.conteudos && value.conteudos.map((conteudo, videoIndex) => (
                          <ButtonBase
                            disabled={this.state.conteudos_order[conteudo.order - 1] != undefined && !this.state.conteudos[this.state.conteudos_order[conteudo.order - 1]]?.watchtime?.completed}
                            className="w100 flex align-center jcsb"
                            style={{
                              whiteSpace: "nowrap",
                              padding: "2px",
                              backgroundColor: (this.state.selectedContent?.id == conteudo.id ? "rgba(0, 0, 0, 0.135)" : "initial")
                            }}
                            key={"video_" + videoIndex}
                            onClick={() => {
                              if (this.state.conteudos_order[conteudo.order - 1] == undefined || this.state.conteudos[this.state.conteudos_order[conteudo.order - 1]]?.watchtime?.completed == true) {
                                window.scrollTo({
                                  top: 0,
                                  behavior: 'smooth'
                                });
                                this.setState({ selectedContent: conteudo });
                              }
                              // history.push(`?video=${video.id}&lesson=${video.lesson_id}`);
                              // SetCurrentVideoPath(video.path);
                            }}
                          >
                            <div style={{ overflow: "hidden" }}>
                              <Checkbox
                                disabled
                                checked={conteudo.watchtime?.completed}
                              />
                              {conteudo.name !== null ? conteudo.name : "Conteúdo sem nome"}
                            </div>
                            {conteudo.type == 'video' ?
                              <VideocamIcon style={{ color: "gray" }} /> :
                              <div>
                                <LaunchIcon
                                  style={{ color: "gray" }}
                                  onClick={(e) => {
                                    // e.stopPropagation();
                                    // !document.completed && VisitedDoc(document.id);
                                    // window.open(STORAGE_URL + document["path"]);
                                  }}
                                  className="icon"
                                />
                                <DescriptionIcon style={{ color: "gray" }} />
                              </div>}
                          </ButtonBase>
                        ))}

                      </AccordionDetails>
                    </Accordion>
                  ))}
                  <div className="w100 inline-flex jcsb margin-top-1">
                    {/* <DefaultButton
                    to={`/courses/course_avaliation/`}
                    bg="primary"
                    icon={<MdDvr style={{ marginRight: 5 }} />}
                    text="Ver avaliação"
                    hidden={!course?.hasExam}
                    style={{ width: '100%', marginTop: '15px', marginRight: 10, marginLeft: 10 }}
                  /> */}
                  </div>
                </TabPanel>
              </div>
              <div
                id="right-side"
                className="flex1 d-lg-block d-none"
              >
                <AccordionSummary
                  style={{
                    fontWeight: 700,
                    borderBottom: "3px solid #351c75",
                    fontSize: "1.2em",
                    background: "#ececf1"
                  }}
                >
                  <div className="flex jcc w100">Conteúdo do curso</div>
                </AccordionSummary>
                <div className={"w100 flex jcc" + (loading && modulos.length == 0 ? "" : " hide")} style={{ marginTop: "5em" }}>
                  <CircularProgress />
                </div>
                <div
                  className="course-content"
                  style={{
                    maxHeight: "31\.8vw",
                    // overflowY: "scroll"
                  }}
                >
                  {modulos.length != 0 && modulos.map((value, index) => (
                    <Accordion
                      key={index}
                      style={{
                        background: "#ececf1"
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{
                          fontWeight: "700",
                          textTransform: "capitalize"
                        }}

                      >
                        <div
                          className="text-truncate"
                          style={{
                            maxWidth: "300px"
                          }}
                        >
                          {index + 1}. {value["name"]}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails
                        className="flex fdcolumn "
                        style={{ padding: 0 }}
                      >
                        {value.conteudos && value.conteudos.map((conteudo, videoIndex) => (
                          <ButtonBase
                            disabled={this.state.conteudos_order[conteudo.order - 1] != undefined && !this.state.conteudos[this.state.conteudos_order[conteudo.order - 1]]?.watchtime?.completed}

                            className="w100 flex align-center jcsb"
                            style={{
                              whiteSpace: "nowrap",
                              padding: "2px",
                              backgroundColor: (this.state.selectedContent?.id == conteudo.id ? "rgba(0, 0, 0, 0.135)" : "initial")
                            }}
                            key={"video_" + videoIndex}
                            onClick={() => {
                              if (this.state.conteudos_order[conteudo.order - 1] == undefined || this.state.conteudos[this.state.conteudos_order[conteudo.order - 1]]?.watchtime?.completed == true) {
                                window.scrollTo({
                                  top: 0,
                                  behavior: 'smooth'
                                });
                                this.setState({ selectedContent: conteudo });
                              }


                              // history.push(`?video=${video.id}&lesson=${video.lesson_id}`);
                              // SetCurrentVideoPath(video.path);
                            }}
                          >
                            <div style={{ overflow: "hidden" }}>
                              <Checkbox
                                disabled
                                checked={conteudo.watchtime?.completed}
                              />
                              {conteudo.name !== null ? conteudo.name : "Conteúdo sem nome"}
                            </div>
                            {conteudo.type == 'video' ?
                              <VideocamIcon style={{ color: "gray" }} /> :
                              <div>
                                <LaunchIcon
                                  style={{ color: "gray" }}
                                  onClick={(e) => {
                                    // e.stopPropagation();
                                    // !document.completed && VisitedDoc(document.id);
                                    // window.open(STORAGE_URL + document["path"]);
                                  }}
                                  className="icon"
                                />
                                <DescriptionIcon style={{ color: "gray" }} />
                              </div>}
                          </ButtonBase>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
                {/* <DefaultButton
                      to={`/courses/course_avaliation/${id}`}
                      bg="primary"
                      icon={<MdDvr style={{ marginRight: 5 }} />}
                      text="Ver avaliação"
                      hidden={!course?.hasExam}
                      style={{ width: '100%', marginTop: '15px' }}
                  />
                  <a
                      href={API_URL + 'courses/certificado/' + course?.id + '/' + userId}
                      target="_blank"
                      className={`remove-a ${!course?.completedCourse ? "clickDisabled" : ""}`}
                      hidden={course?.hasExam}
                  >
                      <DefaultButton
                          text="Baixar certificado"
                          disabled={!course?.completedCourse}
                          style={{ width: '100%', marginTop: '15px' }}
                      />
                  </a> */}
                {/* {userCourse.completed_course === true && <div className="w100 inline-flex jcsb margin-top-1">
                  </div>} */}
              </div>
            </div>


          </>}
          {loading && <div className="flex jcc"><CircularProgress /></div>}
        </div>
      </div>
    )


  }
}

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
  faculdade: state.AppReducer.faculdade,
});

export default connect(mapStateToProps, { logout })(ShowTreinamentos);
