import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";



import { BsArrowRight, BsFillStarFill } from "react-icons/bs";


import TrainingImage from '../../../assets/images/training.jpg'
import { ProgressBar } from "react-bootstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";

const CourseCard = props => {

    const [loading, SetLoading] = useState(false);
    const [redirect, SetRedirect] = useState(<></>);
    const [progress, SetProgress] = useState(0);

    const [last_lesson_id, SetLastLesson] = useState("");
    const [lastVideoId, SetLastVideo] = useState("");
    const history = useHistory();
    const CardClick = (e) => {
        // SetRedirect(<Redirect to={`/courses/course/${props.id}`}/>);
        
    }

    

   

    

    useEffect(() => {
        
    }, []);

    if (loading) return <SkeletonCourseCard/>
    return(
        <div key={props.treinamento.id} id={props.treinamento.id} className="course-card">
            <span onClick={()=>{
                if(props.treinamento.status=='Em andamento'){
                    history.push('/treinamentos/'+props.treinamento.id)
                }
                else{
                    props.SetSnackbar(true);
                    props.SetSnackbarMessage(props.treinamento.status=='Encerrado' ? "Não é possível acessar o treinamento pois o mesmo já encerrou":"Não é possível acessar o treinamento pois o mesmo ainda não iniciou");
                }
            }}  style={{textDecoration: "none",cursor:'pointer'}} className="remove-a" id={props.treinamento.id}>
                <div className="course-card-bar w100"/>
                <div className="course-card-body flex fdrow jcsb">
                    <div>
                        <img src={TrainingImage} className="course-card-img"/>
                    </div>
                    <div className="fdcolumn flex1 jsstart course-card-text" style={{overflow: "hidden"}}>
                        <h2 className="course-card-title">
                            {props.treinamento.nome}
                        </h2>
                        <p style={{marginBottom:5}}><b>Início: </b>{moment(props.treinamento.instituicoes[0].data_inicial).format('DD/MM/YYYY HH:mm')}</p>
                        <p style={{marginBottom:5}}><b>Encerramento: </b>{moment(props.treinamento.instituicoes[0].data_final).format('DD/MM/YYYY HH:mm')}</p>
                        <p style={{marginBottom:10}}><b>Status: </b> <span style={{color:props.treinamento.status=='Não iniciado'?'darkgoldenrod':(props.treinamento.status=='Encerrado'?'red':'green')}}>{props.treinamento.status}</span></p>

                        {/* <p>{props.treinamento.descricao}</p> */}
                        {/* <ContentDotOverflow element="div">
                            {props.description}
                        </ContentDotOverflow> */}
                        <ProgressBar now={progress} className={"margin-bottom" + (props.treinamento.user_data==null ? " hide" : "")} />
                        <div className="inline-flex jcsb w100 align-center">
                            <div>
                            {(() => {
                                if(props.treinamento.status=='Em andamento'){
                                    if (props.treinamento.user_data) return (
                                        props.treinamento.user_data.completed==false? 
                                            <button className="btn btn-primary " >Continuar <BsArrowRight/></button>:
                                        <button className="btn btn-primary " >Visualizar <BsArrowRight/></button>
                                    );
                                    else return (
                                        <button className="btn btn-primary " >Iniciar  <BsArrowRight/> </button>
                                    );
                                }

                            })()}
                            </div>
                            {props.treinamento.user_data!=null && <span>{`${progress}%`}</span>}
                        </div>
                        {/* <div className="rating flex fdrow align-center">
                            <div 
                                className="five-star"
                                style={{
                                    position: "relative",
                                    whiteSpace: "nowrap",
                                    width: "fit-content"
                                }}
                            >
                                <div 
                                    className="stars"
                                    style={{
                                        position: "absolute",
                                        overflow: "hidden",
                                        width: (props.treinamento.average_rate * 100) / 5 + "%",
                                        top: 0,
                                        left: 0
                                    }}
                                >
                                    {(() => {
                                        let rates = [];
                                        for (let i = 0; i < 5; i++) {
                                            rates.push(<BsFillStarFill
                                                key={i}
                                                className="star-over"
                                                size={16}
                                            />)
                                        }
                                        return rates;
                                    })()}
                                </div>
                                {(() => {
                                    let rates = [];
                                    for (let i = 0; i < 5; i++) {
                                        rates.push(<BsFillStarFill
                                            key={i}
                                            className="star-placeholder"
                                            size={16}
                                        />)
                                    }
                                    return rates;
                                })()}
                            </div>
                            <div style={{textIndent: "5px", paddingTop: "3px"}} className="d-inline-flex">
                                {props.treinamento.rating_count != 0 ? props.treinamento.rating_count : <div className="d-md-none d-block">{props.treinamento.rating_count}</div>}
                                <div className="d-none d-md-block">
                                    {props.treinamento.rating_count == 0 ? " Nenhum usuário avaliou o curso ainda" : (props.treinamento.rating_count > 1 ? " Usuários avaliaram" : " Usuário avaliou")}
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="course-card-footer">
                </div>
            </span>
        </div>
    );
};

export const SkeletonCourseCard = () => {
    return (
        <div className="course-card flex fdrow">
            <div className="course-card-body flex fdrow jcsb w100">
                <div className="skeleton-base skeleton-shimmer course-card-img"/>
                <div className="flex fdcolumn flex1" style={{padding: "5px"}}>
                    <div className="skeleton-title skeleton-shimmer"/>
                    <br/>
                    <div className="skeleton-medium-text skeleton-shimmer"/>
                    <br/>
                    <div className="skeleton-base skeleton-shimmer w100" style={{height: ".4em"}}/>
                    <br/>
                    <div className="flex w100 jcsb align-center">
                        <div className="skeleton-small-button skeleton-shimmer"/>
                        <div className="skeleton-base skeleton-shimmer" style={{width: "2em", height: "1em"}}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseCard;