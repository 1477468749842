// Libraries imports
import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import { Alert } from "react-bootstrap";
import CourseCard, { SkeletonCourseCard } from "./CourseCard";

// Components imports
import LoadingSkeleton from "./Components/LoadingSkeleton";
import NextArrow from "./Components/NextArrow";

// Utils imports
import { get, post } from "../../../services/api";
import { logout } from "../../../actions/AppActions";
import { formatDate } from "../../../utils/formatDate";
import { Snackbar } from "@material-ui/core";

const Treinamentos = ({ token, faculdade,logout }) => {
  const [treinamentos, setTreinamentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [userId, setUserId] = useState("");
const [snackbar, SetSnackbar] = useState(false);
    const [snackbarMessage, SetSnackbarMessage] = useState("");

  useEffect(() => {
    getTreinamentos();
  }, []);




  const getTreinamentos = async () => {
    try {
      const data = await get(`api/fiscal/treinamento`, token);
      console.log(data)
      if (!data || !data.treinamentos) {
        if(data?.status=='logout'){
          logout()
        }
        return;
        // throw new Error("Invalid user or trainings data");
      }




      setTreinamentos(data.treinamentos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setRedirect(true);
  };

  // const handleCompleteTraining = async (trainingId, isCompleted) => {
  //   try {
  //     const response = await post(
  //       `api/update_training_status/${userId}`,
  //       {
  //         trainings: [
  //           {
  //             training_id: trainingId,
  //             concluido: isCompleted,
  //           },
  //         ],
  //       },
  //       token
  //     );
  //     return response;
  //   } catch (error) {}
  // };

  if (redirect) return <Redirect to="/" />;



  return (
    <div className="home">
      {/* <div className="head">
                <div id="top">
                    <div className="headline">
                        KZT - Solução de vídeos
                    </div>
                </div>
                
            </div> */}
      {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img className="img-fluid" src={faculdade?.logo} alt="" height="100" style={{ height: '200px' }} />
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }}>

                <h4 style={{ textAlign: 'center', fontWeight: 500 }}>Treinamentos da {faculdade?.name}</h4>
            </div> */}
      <div className="card mt-3">
        <div className="card-body">

          <div id="in-courses">
            <div className="float-right">
              <Link onClick={() => {

              }} to='/' className="btn btn-secondary btn-outline btn-circle m-r-5" ><i
                className="mdi mdi-reply"
                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                  Voltar</i></Link>
            </div>
            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Lista de treinamentos disponíveis</h4>

            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todos os treinamentos que você está vinculado</p>
            <br />
            <br />
            <div style={{ marginLeft: '-1rem', marginRight: '-1rem' }}>
               <Snackbar
                              anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                              }}
                              autoHideDuration={10000}
                              onClose={() => SetSnackbar(false)}
                              open={snackbar}
                              message={snackbarMessage}
                          />

              <div className="cards-group">
                {/* {!loading && links} */}
                {loading ? <>
                  <SkeletonCourseCard />
                  <SkeletonCourseCard />
                  <SkeletonCourseCard />
                </> : <>
                  {treinamentos.map((treinamento, index) => (

                    <CourseCard
                      key={index}
                      treinamento={treinamento}
                      SetSnackbar={SetSnackbar}
                      SetSnackbarMessage={SetSnackbarMessage}

                    />
                  ))}
                  {treinamentos.length==0 && <p style={{textAlign:'center'}}>Nenhum treinamento disponível</p>}

                </>}
              </div>
              <div className="flex align-center jcc" hidden={!loading}>
                {/* <CircularProgress className={loading ? "" : "hide"}/> */}
                <div className="skeleton-medium-dot skeleton-shimmer" />
                <div className="skeleton-medium-dot skeleton-shimmer" />
                <div className="skeleton-medium-dot skeleton-shimmer" />
              </div>
            </div>

          </div>

        </div>
      </div>

      <div className="footer">

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
  faculdade: state.AppReducer.faculdade,
});

export default connect(mapStateToProps, { logout })(Treinamentos);